.modal-wrapper {
   position: fixed;
   left: 0;
   top: 0;
   width: 100%;
   height: 100vh;
   overflow-y: scroll;
   -webkit-overflow-scrolling: touch;
   background-color: rgba(95, 88, 88, 0.5);
   z-index: 1050;
}

.custom-modal {
   overflow-y: scroll;
   position: fixed;
   background-color: white;
   width: 40%;
   height: 100%;
   right: 0;
   top: 0;
   cursor: default;
}
