.lock .ant-table-thead .ant-table-cell {
    background-color: #d7f2ed;
}

.lock .ant-table-row .ant-table-cell {
    background-color: #f5fbfa;
}

.lock .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background-color: #f5fbfa;
}

#lock-btn:hover {
    background-color: #9bdfd2 !important;
    color: black !important;
}

.list .ant-table-thead .ant-table-cell {
    background-color: #f2e0e9;
}

.list .ant-table-row .ant-table-cell {
    background-color: #fcf8fa;
}

.list .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background-color: #fcf8fa;
}

.upload .ant-table-thead .ant-table-cell {
    background-color: #f6eee0;
}

.upload .ant-table-row .ant-table-cell {
    background-color: #fcfaf7;
}

.upload .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background-color: #fcfaf7;
}